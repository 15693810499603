import React from 'react'

import { Text, Utils } from '@azos/shared'
import clsx from 'clsx'

import { ProductResumeProps } from './ProductResume.props'
import { Root } from './ProductResume.styles'

const ProductResume: React.VFC<ProductResumeProps> = ({
  title,
  description,
  value,
  variant = 'primary',
  className,
  parentName,
  code,
  ...rest
}) => {
  return (
    <Root
      title={title}
      className={clsx(`variant-${variant}`, className)}
      data-action={Utils.dataNames.renderDataName('product-resume', parentName)}
      {...rest}
    >
      <div className="content__amount">
        <Text variant="h6" component="strong" className="product-resume__text">
          {title}
        </Text>
        <Text variant="h6" component="strong" className="product-resume__value">
          {Utils.currency.getCoverageChosen(value, code)}
        </Text>
      </div>
      {description && (
        <Text
          variant="body2"
          component="p"
          className="product-resume__text product-resume__text-content"
        >
          {description}
        </Text>
      )}
    </Root>
  )
}

export default ProductResume
