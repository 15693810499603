import { Utils as CoreUtils } from '@azos/core'
import { CoveragesCode } from '@azos/shared/src/lib/coverages'

export const hasDeathCoverage = (coverage: { code: CoveragesCode }) => {
  return CoreUtils.coverages.isDeathCoverage(coverage.code)
}

export const isNotRefused = (coverage: { shouldRefuse: boolean }) => {
  return !coverage.shouldRefuse
}
