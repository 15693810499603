import { HttpClient, makeAxiosHttpClient } from '@azos/core'
import { ListSharedInfoUseCase } from '@domain/usecases'

export const CMSAPI = (httpClient: HttpClient = makeAxiosHttpClient()) => {
  return {
    getListShared: async (): Promise<ListSharedInfoUseCase.Response> => {
      return httpClient
        .request({
          url: '/cms/shared-info',
          method: 'get',
        })
        .then(response => response.data)
    },
  }
}
