import React from 'react'

import { RemoteConfigProvider } from '@azos/core'

import { GeneralDataProvider } from './GeneralDataProvider'

const MainProvider: React.FCC = ({ children }) => {
  return (
    <RemoteConfigProvider>
      <GeneralDataProvider>{children}</GeneralDataProvider>
    </RemoteConfigProvider>
  )
}

export default MainProvider
