import {
  CoveragesCode,
  isDeathCoverage as CoveragesIsDeathCoverage,
  isDihCoverage as CoveragesIsDihCoverage,
  isDisabilityCoverage as CoveragesIsDisabilityCoverage,
  isRitCoverage as CoveragesIsRitCoverage,
  isChildCoverage,
  isSecondaryCoverage,
} from '@azos/shared/src/lib/coverages'

const filterByCoverageCode =
  (codes: CoveragesCode[]) => (code: CoveragesCode) =>
    codes.includes(code)

export const isDeathCoverage = CoveragesIsDeathCoverage

export const isFuneralAssistanceCoverage = isSecondaryCoverage

export const isDisabiltyCoverage = (code: CoveragesCode) => {
  return CoveragesIsDisabilityCoverage(code) || isChildCoverage(code)
}

export const isDGCoverage = filterByCoverageCode([
  CoveragesCode.COD_0004_DOENCAS_GRAVES,
  CoveragesCode.COD_0005_DOENCAS_GRAVES_10,
  CoveragesCode.COD_1005_DOENCAS_GRAVES_10,
  CoveragesCode.COD_2005_DOENCAS_GRAVES_10,
  CoveragesCode.COD_2011_DOENCAS_GRAVES_10,
  CoveragesCode.COD_1015_DOENCAS_GRAVES_13,
  CoveragesCode.COD_2015_DOENCAS_GRAVES_13,
])

export const isDG13Coverage = filterByCoverageCode([
  CoveragesCode.COD_1015_DOENCAS_GRAVES_13,
  CoveragesCode.COD_2015_DOENCAS_GRAVES_13,
])

export const isDIHCoverage = CoveragesIsDihCoverage

export const isRITCoverage = CoveragesIsRitCoverage

export const isSurgeryCoverage = filterByCoverageCode([
  CoveragesCode.COD_2008_CIRURGIAS,
])
