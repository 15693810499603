import { CoveragesCode } from '@azos/shared/src/lib/coverages'

export enum QuotationSaleChannel {
  B2C = 'b2c',
  B2B = 'b2b',
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export type WidgetQuotation = {
  birth_date: Date | string
  height: number
  weight: number
  gender: Gender
  is_smoker: boolean
  price: string
  widgetOrigin: string
}

export type Quotation = {
  quotationId: string
  saleChannel: QuotationSaleChannel
  //
  fullName?: string
  birthDate: Date | string
  height: number
  weight: number
  gender: Gender
  isSmoker: boolean
  professionId?: string
  professionDescription?: string
  salary?: number
  socialName?: string | null
  //
  proposalId: string
  //
  coverages: {
    code: CoveragesCode
    coverageChosen: number
  }[]
  estimated_premiums: {
    code: CoveragesCode
    premium: number
  }[]
}
