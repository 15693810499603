import currency from 'currency.js'

import {
  CoveragesCode,
  isDgsCoverage,
  isDihCoverage,
  isRitCoverage,
} from '../lib/coverages'

export const Currency = (
  value: number | string | currency,
  options?: currency.Options,
) => {
  return currency(value, {
    symbol: 'R$ ',
    separator: '.',
    decimal: ',',
    ...options,
  })
}

export const format = (
  value: number | string | currency,
  symbol = 'R$ ',
): string => {
  return Currency(value, { symbol }).format()
}

export const formatWithoutCents = (
  value: number | string | currency,
  symbol = 'R$ ',
): string => {
  return format(value, symbol).replace(/,.+/, '')
}

export const getLongValue = (value: number): string => {
  const [amount] = formatWithoutCents(value, '').split('.')

  return `R$ ${amount} ${getUnitName(value)}`
}

export const getCoverageChosen = (
  value: number,
  code: CoveragesCode,
): string => {
  const isDG = isDgsCoverage(code)
  const UntilCoveragePresentationList = [
    CoveragesCode.COD_2008_CIRURGIAS,
    CoveragesCode.COD_0002_ASSISTENCIA_FUNERAL,
    CoveragesCode.COD_1002_ASSISTENCIA_FUNERAL,
    CoveragesCode.COD_2002_ASSISTENCIA_FUNERAL,
    CoveragesCode.COD_2016_AFI,
    CoveragesCode.COD_1009_AFF,
    CoveragesCode.COD_2009_AFF,
    CoveragesCode.COD_2017_AFF,
  ]

  if (isDG || UntilCoveragePresentationList.includes(code)) {
    return `Até ${formatWithoutCents(value)}`
  }

  const isDihOrRit = isDihCoverage(code) || isRitCoverage(code)

  return `${formatWithoutCents(value)}${isDihOrRit ? '/dia' : ''}`
}

export const getUnitName = (value: number): string => {
  const nums = format(value, '')

  const amount = nums.replace(/,.+/, '')
  const num = amount.replace('.', '').length
  const [first, second] = amount.split('.')

  if ((num >= 4 && num <= 6) || +second) {
    return 'mil'
  } else if (num >= 7) {
    return +first <= 1 ? 'milhão' : 'milhões'
  } else {
    return +first <= 1 ? 'real' : 'reais'
  }
}
