import { Catalog } from '@Azos-Seguros/catalog'
import { CoveragesCode } from '@Azos-Seguros/catalog/dist/domain/coverages/coverage-code'
import {
  isDgsCoverage,
  isDisabilityCoverage,
  isDeathCoverage,
  isDihCoverage,
  isRitCoverage,
  isDeathRelatedCoverage,
  isSecondaryCoverage,
  isChildCoverage,
} from '@Azos-Seguros/catalog/dist/modules/web/b2b/subscription/data/coverage-validator'
import {
  dihCoveragesCode,
  disabilityCoveragesCode,
  iptaeCoveragesCode,
  afiCoveragesCode,
  affCoveragesCode,
  primaryCoveragesCode,
  secondaryCoveragesCode,
  parentCoveragesCode,
  childCoveragesCode,
  coverageRelations,
  deathCoveragesCode,
} from '@Azos-Seguros/catalog/dist/modules/web/b2b/subscription/domain/coverage-groups'

export const getCoverageInfo = (coverageCode: string, type: string) =>
  (
    Catalog.WEB.B2C_SUBSCRIPTION.getInfoByCoverage(
      coverageCode as CoveragesCode,
    ) as any
  )[type]

export {
  CoveragesCode,
  isDgsCoverage,
  isDisabilityCoverage,
  isDeathCoverage,
  isDihCoverage,
  isRitCoverage,
  isChildCoverage,
  iptaeCoveragesCode,
  dihCoveragesCode,
  disabilityCoveragesCode,
  afiCoveragesCode,
  affCoveragesCode,
  primaryCoveragesCode,
  secondaryCoveragesCode,
  parentCoveragesCode,
  childCoveragesCode,
  coverageRelations,
  deathCoveragesCode,
  isDeathRelatedCoverage,
  isSecondaryCoverage,
}
