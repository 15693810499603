import React from 'react'

import { ListSharedInfoLinks } from '@domain/usecases'
import { CMSAPI } from 'services/cms'

export interface GeneralDataContextValue {
  links: ListSharedInfoLinks
  isLoadingLinks: boolean
}

export const GeneralDataContext = React.createContext(
  {} as GeneralDataContextValue,
)

const DEFAULT_DATA_LINKS = {
  faq: 'https://www.azos.com.br/perguntas-frequentes',
  blog: 'https://www.azos.com.br/vida-segura',
  aboutAzos: 'https://www.azos.com.br/sobre',
  termsOfUse: 'https://www.azos.com.br/termos-de-uso',
  privacyPolicy: 'https://www.azos.com.br/politica-de-privacidade',
  generalCondition: 'https://www.azos.com.br/condicoes-gerais',
  generalConditionsB2C: 'https://www.azos.com.br/condicoes-gerais',
}

export const GeneralDataProvider: React.FCC = ({ children }) => {
  const [links, setLinks] =
    React.useState<ListSharedInfoLinks>(DEFAULT_DATA_LINKS)
  const [isLoadingLinks, setIsLoadingLinks] = React.useState(false)

  React.useEffect(() => {
    setIsLoadingLinks(true)

    CMSAPI()
      .getListShared()
      .then(data => {
        setLinks(data.links)
      })
      .finally(() => {
        setIsLoadingLinks(false)
      })
  }, [])

  return (
    <GeneralDataContext.Provider
      value={{
        links,
        isLoadingLinks,
      }}
    >
      {children}
    </GeneralDataContext.Provider>
  )
}

export const useGeneralData = () => {
  const context = React.useContext(GeneralDataContext)

  if (!context) {
    throw new Error('The useClearsale should within GeneralDataProvider')
  }

  return context
}
