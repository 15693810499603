import { AppError, IProposalConsumer, Services } from '@azos/core'
import { ICoveragesRepository } from '@data/repositories'
import { Coverage2Premium, Coverage2PremiumValue } from '@domain/models'
import { LoadCoverages2PremiumsUseCase } from '@domain/usecases'

type Params = LoadCoverages2PremiumsUseCase.Params
type Response = LoadCoverages2PremiumsUseCase.Response

export type ILoadCoverages2PremiumsService = Services<Params, Response>

export class LoadCoverages2PremiumsService
  implements ILoadCoverages2PremiumsService
{
  constructor(
    private readonly coveragesRepository: ICoveragesRepository,
    private readonly proposalConsumer: IProposalConsumer,
  ) {}

  async execute(params?: Params): Promise<Response> {
    let proposalId = params?.proposalId

    if (!proposalId) {
      proposalId = this.proposalConsumer.execute()?.proposalId
    }

    if (!proposalId) throw new AppError()

    const coverages = await this.coveragesRepository.loadCoverages2Premiums({
      proposalId,
    })

    const formattedCoverages = coverages.map(coverage => {
      const coverage2premium = this.getMinimumPremium(
        coverage.coverage2premium,
        coverage,
      )

      const coverage2premiumSuggestion =
        coverage.coverage2premiumSuggestion.premium <
        coverage2premium[0].premium
          ? coverage2premium[0]
          : coverage.coverage2premiumSuggestion

      let coverage2premiumDeathOnly
      let coverage2premiumDeathOnlySuggestion

      if (
        coverage.coverage2premiumDeathOnly &&
        coverage.coverage2premiumDeathOnlySuggestion
      ) {
        coverage2premiumDeathOnly = this.getMinimumPremium(
          coverage.coverage2premiumDeathOnly ?? [],
          coverage,
        )

        coverage2premiumDeathOnlySuggestion =
          coverage.coverage2premiumDeathOnlySuggestion.premium <
          coverage2premiumDeathOnly[0].premium
            ? coverage2premiumDeathOnly[0]
            : coverage.coverage2premiumDeathOnlySuggestion
      }

      return {
        ...coverage,
        coverage2premium,
        coverage2premiumDeathOnly: coverage2premiumDeathOnly ?? null,
        coverage2premiumSuggestion,
        coverage2premiumDeathOnlySuggestion:
          coverage2premiumDeathOnlySuggestion ?? null,
      }
    })

    return formattedCoverages
  }

  getMinimumPremium = (
    premiums: Coverage2PremiumValue[],
    coverage: Coverage2Premium,
  ): Coverage2PremiumValue[] => {
    const minimumPremium = 5

    const lastFiveIndex = premiums
      .map(item => item.price)
      .lastIndexOf(minimumPremium)

    if (lastFiveIndex === -1) {
      return coverage.coverage2premium
    }

    return premiums.slice(lastFiveIndex).filter(item => {
      return item.price === minimumPremium || item.price > minimumPremium
    })
  }
}
