import { AppError, IProposalConsumer, Services, Storage } from '@azos/core'
import { INotificationsRepository } from '@data/repositories/notifications'
import { NotifyAbandonedCartUseCase } from '@domain/usecases'

type Params = void
type Response = NotifyAbandonedCartUseCase.Response

export type INotifyAbandonedCartService = Services<Params, Response>

export class NotifyAbandonedCartService implements INotifyAbandonedCartService {
  constructor(
    private readonly notificationsRepository: INotificationsRepository,
    private readonly proposalConsumer: IProposalConsumer,
    private readonly storage: Storage,
  ) {}

  async execute(): Promise<Response> {
    const proposalConsumer = this.proposalConsumer.execute()
    console.log('proposalConsumer', { proposalConsumer })
    const proposalId = this.proposalConsumer.execute()?.proposalId

    if (!proposalId) throw new AppError('Erro ao buscar dados de proposta')

    const utmData = this.storage.getJSON('utm')
    const utm = Object.keys(utmData).length === 0 ? '' : JSON.stringify(utmData)

    return await this.notificationsRepository.notifyAbandonedCart({
      proposalId,
      utm,
    })
  }
}
